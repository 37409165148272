<template>
  <div class="viewContainer">
    <div class="center">
      <p class="title1" style="color: rgba(217, 173, 89, 1)">STORY</p>

      <p class="text tt1">
        Out of a world in which wars were becoming more and more frequent, one of the most controversial scientific projects in all history was born : The HYBRID Project. Under the aegis of the Armed Forces Ministry in the land of Yebrak, scientists made creatures that were half-human half-animal.
        The purpose was to form an army of super-powerful hybrids warriors. Years later, Hybrids are now free and dangerous.<br /><br />Three factions left, war is coming, only one will survive…
      </p>
      <button class="dark-button" style="width: 300px; margin: auto; margin-top: 20px" @click="hide = !hide" v-if="hide">Show more</button>

      <div class="blurPage" v-if="!hide"></div>
      <div class="hideStory" v-if="!hide">
        <div style="position: absolute; top: 10px; right: 10px; cursor: pointer" @click="hide = !hide"><i class="fa-solid fa-circle-xmark" style="font-size: 30px"></i></div>
        <h1 class="title1" style="margin-bottom: 25px; color: rgba(217, 173, 89, 1)">STORY</h1>
        <p class="text">
          Some time ago, in the faraway land of Yebrak, the idea of an upcoming war was gaining ground in people’s minds. The old disputes with the enemy would repeat over and over, while tensions along the borders would mount. Sooner or later, war would break out again.
          <br /><br />
          Given the dangerous threats, Yebrak’s Ministry for Armed Forces had no other option but to develop new attack and defense abilities. The plan was to dissuade the enemy, and if that didn’t work, to fight him without mercy.
          <br /><br />
          Under the aegis of the authorities, a team of leading scientists was given the mission of carrying out one of the most controversial scientific projects. For the first time in history, scientists were about to make creatures that were half-human half-animal, in a bid to build an army of
          super-powerful warriors. This top secret defense plan was labeled : the HYBRID Project.
          <br /><br />
          The Ministry for Armed Forces duly equipped Laboratory XyO8626, a massive lab, and gave the scientists the keys. Once inside, law became completely obsolete, moral codes were swept under the carpet, and the very idea of ethics ceased to exist. Given unlimited funds and authorized to carry
          out whatever experiments they needed, the scientists could turn their most transgressive fantasies into palpable reality. There was only one hurdle for them to overcome in creating this army of hybrid warriors : the vagaries of scientific experiments, which always hold a few surprises.
          <br /><br />
          Thousands of phials, containing human and various animal species genomes, began to accumulate in Lab XyO8626. The complex process that would lead to a powerful hybrid warrior, could be summed up in three basic steps.
          <br /><br />
          -Phase 1: Using CRiSP-CAS9 molecular scissors, scientists sliced up DNA, retaining only those sections needed for assembly. They then fused the human and animal genomes, adding among other transgenes, the VjAe55 transgene, drastically amplifying the hybrid’s physical capacities.
          <br />
          -Phase 2: The cells were cloned and then inseminated like seeds, in huge artificial gestation machines, that replicated the exact conditions of life in the uterus.
          <br />
          -Phase 3: The scientists ensured the hybrid fetuses grew. They provided them with the nutrients needed for ectogenesis and most importantly, with the biomolecular potion ERIF808. A top secret defense potion, that would give the hybrids their incredible powers.
          <br /><br />
          Inside the artificial gestation machines, the cells gradually multiplied until the scientists were able to make out indistinct beings, with both human and animal features. The ectogenesis seemed to be working, boosting the researchers’ ego. Mistaking themselves for demigods, the scientists
          were already dreaming of the honors and medals they would receive from the Armed Forces Ministry : training hybrid warriors was now just a matter of time.
          <br /><br />
          However, for inexplicable biomolecular and genetic reasons, only three hybrid species made it to term. These were half-man half-fish, half-man half-lion, and half-man half-eagle. They had the body of a man and the head of the animal, the intelligence of Homo Sapiens and the strength of the
          beast, the language capability of humans and the cry of the animal.
          <br /><br />
          Once they were finished and functioning, the hybrids cooperated happily with their creators. As expected, they obeyed the scientists and orders from Ministry, by practicing secretly in the Lab XyO8626, and forming a dissuasive army of warriors.
          <br /><br />
          But gradually, the hybrids became aware that they were infinitely more powerful than their creators. Why the hell should they let such weak beings dominate them? One fine day, by mutual agreement, the hybrids decided to attack the very persons who had given them life. The scientists would
          all be found assassinated inside Lab XyO8626. The project instigated by the Armed Forces Ministry had failed. The creatures had taken back their freedom and rights.
          <br /><br />
          And when hybrids designed for combat are let loose in the same arena, it can only end in strong rivalry ! A ferocious struggle took place to decide which of the three species, half-man half-fish, half-lion or half-eagle, would now rule over the others. With no clear winner emerging,
          wanting to avoid deaths within their own ranks for the time being, the hybrids went their own way, putting off the fight for later. The fishmen headed for the ocean, the eaglemen took to the skies, and the lionmen set off on land.
          <br /><br />
          Each of the three hybrid factions would conquer and populate new territory, developing their own clan, and proving their power. The war between the water faction, the air faction, and the earth faction had only just begun...
        </p>
        <button class="dark-button" style="width: 300px; margin: auto; margin-top: 20px" @click="hide = !hide">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Join",
  data() {
    return {
      hide: true,
    };
  },
};
</script>
<style lang="scss" scoped>
.justify-center {
  width: 900px !important;
  max-width: 900px !important;
  margin: auto;
}

.colCont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;
}

.col {
  width: 30%;
  align-items: center;
}

.pic {
  width: 100%;
  height: auto;
}

.tt1 {
  text-align: left;
  font-size: 28px;
  line-height: 28px;
}

.blurPage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.61);

  backdrop-filter: blur(5px);
  z-index: 299;
}

.hideStory {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  // background-color: $black;
  // padding: 10px 25px;
  // border-radius: 10px;
  // margin-bottom: 50px;
  // border: 4px solid rgba(95, 95, 95, 0.398);
  //filter: drop-shadow(0 0 3px $white);

  padding: 10px 25px;
  border: 1px solid rgba(49, 60, 83, 0.698);
  background: rgba(24, 31, 44, 0.316);
  border-radius: 8px;
  backdrop-filter: blur(8px);

  width: 80%;
  max-width: 1200px;
  height: 700px;
  overflow-y: scroll;
  text-align: center;
  z-index: 300;
  margin: auto;
}

@media screen and (max-width: 821px) {
  .glow-button {
    font-size: 4vw !important;
  }
}
</style>

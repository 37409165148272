import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    social: {
      instagram: 'https://www.instagram.com/hybrid_factions/',
      discord: 'https://discord.gg/xUPMhCgRgM',
      twitter: 'https://twitter.com/HybridFactions2',
    },
    topBar: {
      bgColor: '#0e091f',
      position: 'fixed',
      // logo: require(`@/assets/logos/logo.png`),
      title: '',
    },
    roadmapView: {
      bgColor: 'transparent',
      sections: [
        {
          title: 'PROPHASE',
          text: "Hybrid factions is an ultra-realistic and very detailed 3D collection.</br></br>A story worthy of the greatest scenarios and staged by artists coming from these renowned universes. </br></br>This collection will entail unique pieces of Hybrid With more than 200 variations.</br></br>Your NFT's rarity rank will be easily accessible through the two major rarity services at this time.",
        },
        {
          title: 'METAPHASE',
          text: "The animal cause is very important to us, that’s why we will give a percentage of the mint to the PETA association.</br></br> There is no way that a story written by such talented people could not end with a movie.</br></br>Netflix and Amazon Prime Videos subscriptions will be given to holders</br></br>We are the first Metaverse Watch2Earn community to produce a movie.</br></br>Creation of the First Cinematographic Community in the Metaverse.</br></br>All the opensea royalties will be used to fund the advancement of the project.",
        },
        {
          title: 'ANAPHASE',
          text: "Holders will have access to private events, meeting with actors.</br></br>Meetings, Conferences and Events online and in real life will be offered to holders.</br></br>Depending on your role in the community, some of you will be active protagonists of the movie.</br></br>Best writers and best Musicians will participate to the development of the story and original soundtrack.</br></br>You will have the possibility to access the backstage of the realization.",
        },
        {
          title: 'TELOPHASE',
          text: "You will have access to the preview of the movie, be part of it as an assistant, a scenarist, or more.</br></br>The price of your NFTs will continue to accumulate value and demand for this unique collection of movie fans will continue to rise, resulting in a huge increase of rarity.</br></br>Your dream will come true and as all work deserves a salary, the holders will receive a percentage of the profits generated by the movie and thus will receive passives incomes.</br></br>Depending on the rarity of your NFT, you will see your hybrid playing as a main or secondary role in the film.",
        }
      ]
    },
    team: [
      {
        name: "Vince",
        title: "Founder | Artistic and story telling supervisor",
        pic: require("@/assets/team/King-Air.jpg"),
        insta: "",
        linkedin: "",
        artstation: "",
        discord: "",
        twitter: "",
      },
      {
        name: "Zayro",
        title: "Founder | Tech & Crypto Expert",
        pic: require("@/assets/team/King-Lion.jpg"),
        insta: "",
        linkedin: "",
        artstation: "",
        discord: "",
        twitter: "",
      },
      {
        name: "Gogz",
        title: "Founder | Marketing strategist & Design adviser",
        pic: require("@/assets/team/King-Fish.jpg"),
        insta: "",
        linkedin: "",
        artstation: "",
        discord: "",
        twitter: "",
      },
      {
        name: "Renan",
        title: "3D Artist | Sculptor",
        pic: require("@/assets/team/Second-Fish.jpg"),
        insta: "",
        linkedin: "",
        artstation: "https://www.artstation.com/renanassuncao/profile",
        discord: "",
        twitter: "",
      },
      {
        name: "Raphael G. / Matheo M. / Nolan N.",
        title: "Earth & Fire Faction :</br>3D Modeler / Texturing",
        pic: require("@/assets/team/First-Lion.jpg"),
        insta: "",
        linkedin: "",
        artstation: "",
        discord: "",
        twitter: "",
      },
      {
        name: "Julian C.",
        title: "Biologist & Writer",
        pic: require("@/assets/team/fire.jpg"),
        insta: "",
        linkedin: "",
        artstation: "",
        discord: "",
        twitter: "",
      },
      {
        name: "Cedric N. / Brice P.",
        title: "Water and Air Faction :</br>3D Modeler / Texturing",
        pic: require("@/assets/team/Second-Air.jpg"),
        insta: "",
        linkedin: "",
        artstation: "",
        discord: "",
        twitter: "",
      },

      {
        name: "Kevin D.",
        title: "Cyber Security Developer",
        pic: require("@/assets/team/First-Fish.jpg"),
        insta: "",
        linkedin: "https://www.linkedin.com/in/kevin-didelot/",
        artstation: "",
        discord: "",
        twitter: "",
      },
      {
        name: "Tanguy D.",
        title: "Website Developer",
        pic: require("@/assets/team/First-Fish.jpg"),
        insta: "",
        linkedin: "https://www.linkedin.com/in/dtanguy/",
        artstation: "",
        discord: "",
        twitter: "",
      },
      {
        name: "John & Lana",
        title: "Community Manager",
        pic: require("@/assets/team/Second-Fish.jpg"),
        insta: "",
        linkedin: "",
        artstation: "",
        discord: "",
        twitter: "",
      },
      {
        name: "Supa",
        title: "Head Tech Discord Manager",
        pic: require("@/assets/team/First-Air.jpg"),
        insta: "",
        linkedin: "",
        artstation: "",
        discord: "",
        twitter: "",
      },
    ],
    faq: [
      {
        q: "When is the mint ?",
        r: "Our mint will be held on Tuesday, February 22.",
      },
      {
        q: "What is the supply of Hybrids ?",
        r: "We have a collection of 8,626 unique Hybrids with over 200 variations.",
      },
      {
        q: "What is the minting price ?",
        r: "WL & Raffle winners : 0.08eth, Public sale : 0.12eth",
      },
      {
        q: "How to get WL ?",
        r: "Be hand-picked by being active on our discord. Win a WL Spot through our contest. Win a WL Spot giveaway on social media.",
      },
      {
        q: "How can I buy my hybrid ?",
        r: "You will be able to mint it if you are on the Whitelist or selected on the Raffle. Try to join our discord to be aware of the news !",
      },
      {
        q: "How can I know the rarity ?",
        r: "When the reveal will be live, you will be able to know your rank via the two biggest ranking systems.",
      },
      {
        q: "Which blockchain will be used ?",
        r: "We are living on the Etherum Network and using an ERC721 A contract.",
      },
      {
        q: "How many Hybrids NFT can I mint ?",
        r: "WL & Raffle winners : 4 NFTs / wallet, Public Sale : 2 NFTs / wallet",
      }
    ]
  }
})

<template>
  <div class="viewContainer whatIsView" id="whatis">
    <!-- <img id="planet" :src="require(`@/assets/imgs/planet.png`)" />
    <img id="orb-1" :src="require(`@/assets/imgs/orb-2.svg`)" /> -->
    <div class="center whatIsCenter">
      <p class="title1 wi1">About us</p>
      <!-- <img :src="require(`@/assets/imgs/gold-line.svg`)" /> -->
      <div id="paragraphs">
        <AutoType
          class="text"
          :active="type"
          :txt="`Hybrid Factions is a collection of 8,626 unique 3D realistic Hybrids on the Ethereum Blockchain. Over 200 different traits & 4 different basis characters, designed by Netflix & Marvel artists. The 1st Watch2Earn NFT project that stands out by its uniqueness.</br></br>A story worthy of a movie script, realistic 3D designs adapted to the environments of each faction and an unprecedented roadmap. `"
        />
        <!-- <p class="text">Hybrid Factions is a collection of 8,626 unique 3D realistic Hybrids on the Ethereum Blockchain. Over 150 different traits & 4 different basis characters, designed by Netflix & Marvel artists. The 1st Watch2Earn NFT project that stands out by its uniqueness.</p>
        <p class="text">A story worthy of a movie script, realistic 3D designs adapted to the environments of each faction, and a new roadmap.</p> -->
      </div>

      <button class="glow-button wi2" @click="goToExternal($store.state.social.discord)"><i class="fab fa-discord"></i> Join discord</button>

      <!-- <img id="cats-line" class="wi5" :src="require(`../assets/cats-line.png`)" alt="Cats line" /> -->
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import AutoType from "@/components/AutoType";

export default {
  name: "WhatIs",
  data: () => ({
    type: false,
  }),
  components: { AutoType },
  mounted() {
    if (!this.isMobile()) {
      this.scrollAnimation();
    } else {
      this.type = true;
    }
  },
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    },
    goToExternal(url) {
      window.open(url);
    },
    scrollAnimation() {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: ".wi1",
            start: "center center",
            end: "center center",
            markers: this.scrollMarker,
            scrub: false,
            pin: false,
          },
        })
        .from(".wi1", { opacity: 0 })
        .call(() => {
          this.type = true;
        })
        .from(".wi2", { opacity: 0 });
    },
  },
};
</script>

<style lang="scss" scoped>
.whatIsView {
  //background: linear-gradient(transparent, #2e2e2e7b);
  //background: no-repeat url("~@/assets/imgs/backgroundSpace.webp");
}

#planet {
  position: absolute;
  top: -5%;
  left: 0;
  z-index: 0;
  opacity: 0.8;
}
#orb-1 {
  position: absolute;
  right: -20%;
  bottom: -45%;
  z-index: 0;
  opacity: 0.3;
}

.whatIsCenter {
  //border: 1px solid red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 900px;
  margin: auto;
  button {
    padding: 20px 30px;
    width: 300px;
    margin: auto;
    margin-top: 50px;
  }
}

#paragraphs {
  height: 300px;
}

.text {
  text-align: center;
  font-size: 28px;
  text-align: center;
  line-height: 28px;
}

@media screen and (max-width: 961px) {
  #paragraphs {
    height: 500px;
  }
}
</style>

<template>
  <div id="home" class="viewContainer topView">
    <img id="orb-1" class="a1" :src="require(`../assets/imgs/orb-1.png`)" />
    <!-- <img id="orb-2" class="a2" :src="require(`../assets/imgs/orb-2.png`)" /> -->
    <img id="cats-group" class="a4" :src="require(`@/assets/imgs/aigle-03_5_.png`)" />
    <div class="circle a4"></div>
    <div class="circle2 a4"></div>
    <svg>
      <filter id="wave">
        <feTurbulence x="0" y="0" baseFrequency="0.009" numOctaves="5" seed="2">
          <animate attributeName="baseFrequency" dur="30s" values="0.02;0.005;0.02" repeatCount="indefinite" />
        </feTurbulence>
        <feDisplacementMap in="SourceGraphic" scale="30" />
      </filter>
    </svg>

    <div class="center topCenter">
      <div class="left t2">
        <h1 class="title1 t1">Join the <span style="color: #d9ad59">war</span></h1>
        <p class="title2">
          The 1st Watch2Earn NFT project.<br /><br />
          Enter our world and choose your faction
        </p>

        <div class="inline">
          <button class="glow-button" @click="goToExternal($store.state.social.discord)"><i class="fab fa-discord" @click="goToExternal($store.state.social.discord)" v-if="$store.state.social.discord != ''"></i> Join discord</button>
          <!-- <button class="dark-button">Join our discord</button> -->
        </div>

        <!-- <div class="inline">
          <div class="numbers">
            <h2 style="margin-left: 25px">4<span style="color: #d9ad59"></span></h2>
            <p class="text" style="margin-top: -40px">Factions</p>
          </div>
          <div class="numbers">
            <h2>200<span style="color: #d9ad59">+</span></h2>
            <p class="text" style="margin-top: -40px; margin-left: 10px">Traits</p>
          </div>
        </div> -->
      </div>
      <div class="right"></div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";

export default {
  name: "Home",
  data: () => ({}),
  mounted() {
    if (!this.isMobile()) {
      this.scrollAnimation();
    }
  },
  methods: {
    scrollAnimation() {
      gsap.from(".t2", { x: -innerWidth, opacity: 0, delay: 1, duration: 0.5 });
      gsap.from(".a1", { x: -innerWidth, opacity: 0, delay: 0.5, duration: 0.5 });
      gsap.from(".a4", { opacity: 0, delay: 2, duration: 0.5 });
    },
    goToExternal(url) {
      window.open(url);
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.topView {
  // background: no-repeat url("~@/assets/imgs/backgroundSpace.webp");
  // width: 100%;
  // max-width: 100%;
}

#orb-1 {
  position: absolute;
  top: -10vw;
  left: -20%;
  z-index: 0;
  width: 45vw;
  opacity: 0.8;
}
#orb-2 {
  position: absolute;
  right: -10%;
  bottom: -20vw;
  z-index: 0;
  width: 70vw;
  opacity: 0.8;
}
.circle {
  position: absolute;
  right: 3vw;
  bottom: 15vh;
  width: 40vw;
  height: 40vw;
  z-index: 1;
  filter: url(#wave);
  &::before {
    content: "";
    position: absolute;
    top: 100px;
    left: 100px;
    right: 100px;
    bottom: 100px;
    border: 10px solid #fff;
    border-radius: 50%;
    box-shadow: 0 0 50px rgb(0, 130, 252), inset 0 0 50px rgba(0, 89, 255, 0.316);
    filter: url(#wave) blur(10px);
  }
}
svg {
  display: none;
}

#cats-group {
  position: absolute;
  bottom: 0;
  right: 4vw;
  bottom: 1vw;
  width: 40vw;
  z-index: 10;
}

.topCenter {
  //border: 1px solid red;
  max-width: 90%;
  margin: auto;
  // margin-left: 100px;
  text-align: left;
}

.left,
.right {
  width: 50%;
}

.numbers h2 {
  font-size: 2.7vw;
}

.t1 {
  margin-top: -50px;
  color: white;
  font-size: 6vw;
  font-weight: 800;
  line-height: 6vw;
  z-index: 2;
}

.inline {
  justify-content: flex-start;
  button {
    margin: 0px 20px;
  }
  .numbers {
    margin: 0px 20px;
  }
}

@media screen and (max-width: $layout-breakpoint-medium) {
  .t1 {
    font-size: 9.5vw;
    line-height: 9.5vw;
  }
  .numbers {
    font-size: 3vw;
    z-index: 4;
    .text {
      font-size: 15px;
    }
  }
  .numbers h2 {
    font-size: 5vw;
    margin-bottom: 35px;
  }

  #cats-group,
  .circle {
    display: none;
  }
  .topCenter {
    max-width: 90%;
    margin: auto;
    text-align: center;
  }
  .left {
    width: 100%;
  }
  .right {
    width: 50%;
  }

  .inline {
    justify-content: space-evenly;
    button {
      margin: 0px 20px;
    }
    .numbers {
      margin: 0px 20px;
    }
  }
}
</style>

<template>
  <div id="app">
    <img class="bgImg" :src="require('@/assets/imgs/fixBg.jpeg')" />
    <Topbar v-if="this.$route.name === 'Home'" />

    <!-- <button v-if="this.$route.name !== 'Raffle'" class="glow-button sticky" @click="goTo('Raffle')">Raffle now !</button> -->
    <router-view />
    <Footer v-if="this.$route.name === 'Home'" />
  </div>
</template>

<script>
import Topbar from "@/components/Topbar";
import Footer from "@/components/Footer";

export default {
  name: "App",
  components: { Topbar, Footer },
  methods: {
    goTo(name) {
      console.log(name);
      if (this.$route.name !== name) {
        this.$router.push({ name });
      }
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&display=swap");
@font-face {
  font-family: "ReadexPro-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("~@/assets/fonts/Readex Pro/ReadexPro-Regular.ttf") format("truetype");
}
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap");

html,
body,
#app {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  background-color: $black;
  zoom: normal;
}
#app {
  font-family: ReadexPro-Regula, "Inconsolata", monospace;
  text-align: center;
  color: $white;
  margin: auto;
  overflow-x: hidden;
  background-color: $black;
}

* {
  box-sizing: border-box;
}

.sticky {
  position: fixed;
  top: calc(80px + 2%);
  left: 50%;
  transform: translateX(-50%);
  font-size: 30px;
  text-transform: uppercase;
  cursor: pointer;
  z-index: 1000;
  &:hover {
    filter: drop-shadow(0px 0px 2px #ffffff);
    opacity: 1;
    transform: translate(-50%, -1px);
  }
}

.bgImg {
  width: 100vw;
  height: 100vh;
  opacity: 0.3;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
}

a {
  color: $white;
  text-decoration: none;
}

.viewContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  //border: 1px solid blue;
}

.center {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  padding-top: 150px;
  padding-bottom: 50px;
  z-index: 10;
  margin: auto;
  //border: 1px solid green;
}

.title1,
.title2 {
  //font-family: "Inconsolata", monospace;
  font-family: "Orbitron", sans-serif;
  font-weight: 800;
  margin: auto;
  margin-bottom: 50px;
  text-transform: uppercase;
}
.title1 {
  font-size: 70px;
  line-height: 70px;
}
.title2 {
  font-size: 33px;
  line-height: 35px;
}

.text {
  text-align: left;
  color: #fff;
  //font-family: "Inconsolata", monospace;
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
}

.inline {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

button {
  font-family: "Orbitron", sans-serif;
  text-align: center;
  font-size: 20px;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  z-index: 1000;
  &:hover {
    filter: drop-shadow(0px 0px 2px #ffffff);
    opacity: 1;
    transform: translateY(-1px);
  }
}

.glow-button {
  text-decoration: none;
  color: white !important;
  background: linear-gradient(135deg, rgba(217, 173, 89, 1) 0%, rgba(162, 128, 62, 1) 100%);
  padding: 10px 30px;
  border-radius: 4px;
  font-weight: normal;
  box-shadow: 0 0 15px rgb(255, 255, 255, 0.4) !important;
  cursor: pointer;
  z-index: 2;
}

.dark-button {
  text-decoration: none;
  color: white !important;
  background: #2c2f33;
  padding: 10px 30px;
  border-radius: 4px;
  font-weight: normal;
  box-shadow: 0 2px 15px rgb(44, 47, 51, 0.4) !important;
  z-index: 2;
}

@media screen and (max-width: $layout-breakpoint-xlarge) {
  .center {
    width: calc(100% - 50px);
    margin-left: 25px;
    margin-right: 25px;
  }
}

@media screen and (max-width: $layout-breakpoint-large) {
  .title1 {
    font-size: 60px;
  }
  .title2 {
    font-size: 30px;
  }
  .text {
    font-size: 23px;
  }
}

@media screen and (max-width: $layout-breakpoint-medium) {
  .title1 {
    font-size: 50px;
    margin-bottom: 25px;
  }
  .title2 {
    font-size: 30px;
    margin-bottom: 25px;
    line-height: 40px;
  }
  .text {
    font-size: 20px;
    font-weight: 300;
    line-height: 35px;
  }
  .center {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  button {
    padding: 20px 40px;
    border-radius: 15px;
  }
}

@media screen and (max-width: $layout-breakpoint-small) {
  .title1 {
    font-size: 45px;
  }
  .title2 {
    font-size: 25px;
  }
  .text {
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
  }
  .center {
    padding-top: 50px;
    padding-bottom: 0px;
  }
  button {
    padding: 20px 30px;
    border-radius: 15px;
  }
}
</style>

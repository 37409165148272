<template>
  <div>
    <Home />
    <WhatIs />
    <Factions />
    <Story />
    <!-- <Types /> -->
    <Roadmap />
    <Team />
    <FAQ />
  </div>
</template>

<script>
import Home from "@/components/Home";
import WhatIs from "@/components/WhatIs";
import Factions from "@/components/Factions";
import Story from "@/components/Story";
// import Types from "@/components/Types";
import Roadmap from "@/components/Roadmap";
import Team from "@/components/Team";
import FAQ from "@/components/FAQ";

export default {
  name: "HomeView",
  components: {
    Home,
    WhatIs,
    Factions,
    Story,
    // Types,
    Roadmap,
    Team,
    FAQ,
  },
};
</script>

<style></style>

import Vue from "vue";
import Router from "vue-router";
import Home from "./views/HomeView.vue";
// import Mint from "./views/Mint.vue";
// import Raffle from "./views/Raffle.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    /*{
      path: "/mint",
      name: "Mint",
      component: Mint,
    },
    {
      path: "/raffle",
      name: "Raffle",
      component: Raffle,
    },*/
  ],
});

<template>
  <div class="teamMember">
    <img class="pict" :src="pic" />
    <p class="text name">{{ name }}</p>
    <p class="text title" v-html="title"></p>
    <div class="socialsContainer2">
      <div class="logoContainer2" v-if="insta != ''" @click="goToExternal(insta)">
        <i class="fab fa-instagram"></i>
      </div>
      <div class="logoContainer2" v-if="twitter != ''" @click="goToExternal(twitter)">
        <i class="fab fa-twitter" />
      </div>
      <div class="logoContainer2" v-if="discord != ''" @click="goToExternal(discord)">
        <i class="fab fa-discord"></i>
      </div>
      <div class="logoContainer2" v-if="linkedin != ''" @click="goToExternal(linkedin)">
        <i class="fab fa-linkedin"></i>
      </div>
      <div class="logoContainer2" v-if="artstation != ''" @click="goToExternal(artstation)">
        <i class="fa fa-link"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamCard",
  props: {
    name: String,
    title: String,
    pic: String,
    insta: String,
    linkedin: String,
    discord: String,
    artstation: String,
    twitter: String,
  },
  data() {
    return {
      style: "",
    };
  },
  mounted() {},
  methods: {
    goToExternal(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.teamMember {
  transition: all 500ms ease-in-out;
  margin-bottom: 50px;
  margin-left: 30px;
  margin-right: 30px;
  width: 300px;
  > * {
    transition: all 100ms ease-in-out;
  }
  font-family: "Orbitron", sans-serif;
  //border: 1px solid blue;
}

.name {
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  margin: auto;
  margin-top: 10px;
  width: 100%;
  text-align: center;
  color: $white;
  //font-family: "Rowdies", cursive;
}

.title {
  font-size: 15px;
  line-height: 28px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;

  width: 100%;
  text-align: center;
  color: $white;
  //font-family: "Rowdies", cursive;
}

.pict {
  width: 200px;
  height: 200px;
  object-fit: cover;
  transition: all 400ms ease-in-out;
  border-radius: 500px;
  margin: auto;
  filter: drop-shadow(0px 0px 3px #ffffff);
}

.socialsContainer2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.logoContainer2 {
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //opacity: 0.85;
}

.fa,
.fab {
  color: $white;
  font-size: 25px;
  //opacity: 0.85;
  cursor: pointer;
  &:hover {
    filter: drop-shadow(0px 0px 1px $white);
    opacity: 1;
    transform: translateY(-1px);
  }
}

.fa {
  font-size: 22px;
}

@media screen and (max-width: $layout-breakpoint-medium) {
  .teamMember {
    margin-bottom: 50px;
    margin-left: -10px;
    margin-right: -10px;
  }
  .pict {
    width: 150px;
    height: 150px;
  }
  .name {
    width: 200px;
    font-size: 18px;
  }
  .fa {
    font-size: 22px;
  }
  .fab {
    font-size: 25px;
  }
  .logoContainer2 {
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //opacity: 0.85;
  }
  .socialsContainer2 {
    margin-left: 10px;
    margin-top: 0px;
  }
}
</style>

<template>
  <div class="topbar-container" v-bind:style="{ background: $store.state.topBar.bgColor, position: $store.state.topBar.position }">
    <div class="topbar">
      <div @click="goTo('Home')" class="title2 hideme" style="cursor: pointer; margin: auto 0; font-size: 22px">Hybrid <span style="color: #d9ad59">Factions</span></div>

      <div class="hideme" style="width: 20%"></div>
      <a href="#home" class="nav-item mainNav">Home</a>
      <a href="#whatis" class="nav-item mainNav">Project</a>
      <a href="#roadmap" class="nav-item mainNav">Roadmap</a>
      <a href="#team" class="nav-item mainNav">Team</a>
      <a href="#faq" class="nav-item mainNav">FAQ</a>
      <button class="glow-button" @click="goToExternal('https://mint.hybrid-factions.com')">Mint now !</button>

      <div class="right">
        <i class="fab fa-discord" @click="goToExternal($store.state.social.discord)" v-if="$store.state.social.discord != ''"></i>
        <i class="fab fa-twitter" @click="goToExternal($store.state.social.twitter)" v-if="$store.state.social.twitter != ''"></i>
        <i class="fab fa-instagram" @click="goToExternal($store.state.social.instagram)" v-if="$store.state.social.instagram != ''"></i>
      </div>

      <div class="burger">
        <i class="fa fa-bars" @click="hide = !hide"></i>
      </div>
    </div>

    <div class="mobile" v-if="hide">
      <a href="#home" class="mob-nav-item" @click="hide = false">Home</a>
      <a href="#whatis" class="mob-nav-item" @click="hide = false">Project</a>
      <button class="glow-button" @click="goTo('Raffle')">Raffle now !</button>
      <a href="#roadmap" class="mob-nav-item" @click="hide = false">Roadmap</a>
      <a href="#team" class="mob-nav-item" @click="hide = false">Team</a>
      <a href="#faq" class="mob-nav-item" @click="hide = false">FAQ</a>
      <div class="mob-nav-item mobLogo" style="justify-content: center">
        <i class="fab fa-discord" @click="goToExternal($store.state.social.discord)" v-if="$store.state.social.discord != ''"></i>
        <i class="fab fa-twitter" @click="goToExternal($store.state.social.twitter)" v-if="$store.state.social.twitter != ''"></i>
        <i class="fab fa-instagram" @click="goToExternal($store.state.social.instagram)" v-if="$store.state.social.instagram != ''"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Topbar",
  data() {
    return {
      hide: false,
    };
  },
  mounted() {
    window.addEventListener("resize", this.onresize);
  },
  methods: {
    onresize() {
      this.hide = false;
    },
    goToExternal(url) {
      window.open(url);
    },
    goTo(name) {
      console.log(name);
      if (this.$route.name !== name) {
        this.$router.push({ name });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.topbar-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 4000;
  font-style: normal;
  font-weight: 400;
  color: $white;
  font-family: "Orbitron", sans-serif;
}

.topbar {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  height: 80px;
  padding: 0px 50px;
  margin-top: 10px;
  width: 100%;
  //max-width: 1200px;
  margin: auto;
  background: transparent;
  align-items: center;
  z-index: 5000;
}

.navCont {
  border: 1px solid red;
}

.nav-item {
  height: 40px;
  letter-spacing: 0;
  line-height: 40px;
  //font-size: 35px;
  font-weight: 600;
  font-size: 20px;
  margin-right: 25px;
  cursor: pointer;
  //text-transform: uppercase;
  //opacity: 0.9;
  transition: all 100ms ease-in-out;
  &:hover {
    filter: drop-shadow(0px 0px 1px #ffffff);
    opacity: 1;
    transform: translateY(-1px);
  }
}

.logo {
  height: 80px;
  z-index: 100;
  margin-right: 30px;
  cursor: pointer;
  object-fit: cover;
  &:hover {
    filter: drop-shadow(0px 0px 1px #ffffff);
    opacity: 1;
    transform: translateY(-1px);
  }
}

.right {
  //width: 190px;
  //width: 150px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  .fab {
    color: rgba(217, 173, 89, 1);
    font-size: 25px;
    cursor: pointer;
    opacity: 0.9;
    transition: all 100ms ease-in-out;
    margin-right: 15px;
    &:hover {
      filter: drop-shadow(0px 0px 1px #ffffff);
      opacity: 1;
      transform: translateY(-1px);
    }
  }
  button {
    margin-left: 50px;
  }
}

.burger {
  display: none;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-right: -20px;

  .fa {
    font-size: 40px;
    padding: 11px;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
    //opacity: 0.9;
    transition: all 100ms ease-in-out;
    &:hover {
      filter: drop-shadow(0px 0px 1px #ffffff);
      opacity: 1;
      transform: translateY(-1px);
    }
  }
}

.mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: absolute;
  top: 80px;
  left: 0px;
  width: 100%;
}

.mob-nav-item {
  align-items: center;
  white-space: nowrap;
  user-select: none;
  padding: 12px 20px;
  color: $white;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  transition: all 100ms ease-in-out;
  background-color: #0e091f;
  filter: drop-shadow(0px 0px 1px #ffffff);
  &:hover {
    background-color: #1d1537;
    filter: drop-shadow(0px 0px 1px #ffffff);
    opacity: 1;
  }
  .fab {
    //opacity: 0.9;
    transition: all 100ms ease-in-out;
    margin: 0 10px;
    &:hover {
      filter: drop-shadow(0px 0px 1px #ffffff);
      opacity: 1;
      transform: translateY(-1px);
    }
  }
}

@media screen and (max-width: 1600px) {
  .hideme {
    display: none;
  }
  .right {
    button {
      display: none;
    }
  }
}

@media screen and (max-width: 1200px) {
  .hideme {
    display: none;
  }
  .right {
    display: none;
  }

  .topbar-container {
    position: fixed;
    // border-bottom: 1px solid $gris-light;
  }
  .right {
    display: none;
  }
  .topbar {
    // background-color: $gris-dark;
    margin-top: 0px;
    width: 100%;
  }
  .burger {
    display: flex;
  }
  .logo-png-transparent {
    margin-left: -15px;
    margin-top: -5px;
  }
  .mainNav {
    display: none;
  }
}
</style>

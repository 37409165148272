<template>
  <!-- TEAM -->
  <div id="team" class="viewContainer teamView">
    <div class="center">
      <p class="title1 tt1">Team</p>
    </div>
    <div class="contentTeam ct2">
      <TeamCard v-for="(item, i) in team1" :key="'t' + i" :name="item.name" :title="item.title" :pic="item.pic" :twitter="item.twitter" :insta="item.insta" :linkedin="item.linkedin" :discord="item.discord" :artstation="item.artstation" :id="'pcard1' + i" />
    </div>
    <div class="contentTeam ct2">
      <TeamCard v-for="(item, i) in team2" :key="'t' + i" :name="item.name" :title="item.title" :pic="item.pic" :twitter="item.twitter" :insta="item.insta" :linkedin="item.linkedin" :discord="item.discord" :artstation="item.artstation" :id="'pcard2' + i" />
    </div>
    <div class="contentTeam ct2">
      <TeamCard v-for="(item, i) in team3" :key="'t' + i" :name="item.name" :title="item.title" :pic="item.pic" :twitter="item.twitter" :insta="item.insta" :linkedin="item.linkedin" :discord="item.discord" :artstation="item.artstation" :id="'pcard3' + i" />
    </div>
  </div>
</template>

<script>
import TeamCard from "@/components/TeamCard";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "Team",
  components: { TeamCard },
  data() {
    return {
      team1: [this.$store.state.team[0], this.$store.state.team[1], this.$store.state.team[2]],
      team2: [this.$store.state.team[3], this.$store.state.team[4], this.$store.state.team[5], this.$store.state.team[6]],
      team3: [this.$store.state.team[7], this.$store.state.team[8], this.$store.state.team[9], this.$store.state.team[10]],
    };
  },
  mounted() {
    if (!this.isMobile()) {
      this.scrollAnimation();
    }
  },
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    },
    scrollAnimation() {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: ".tt1",
            start: "center center",
            end: "center center",
            markers: this.scrollMarker,
            scrub: false,
            pin: false,
          },
        })
        .from(".tt1", { opacity: 0 })
        .from("#pcard10", { x: -innerWidth, opacity: 0 })
        .from("#pcard11", { opacity: 0 })
        .from("#pcard12", { x: innerWidth, opacity: 0 })
        .from("#pcard20", { x: -innerWidth, opacity: 0 })
        .from("#pcard21", { x: -innerWidth, opacity: 0 })
        .from("#pcard22", { x: innerWidth, opacity: 0 })
        .from("#pcard23", { x: innerWidth, opacity: 0 })
        .from("#pcard30", { x: -innerWidth, opacity: 0 })
        .from("#pcard31", { x: -innerWidth, opacity: 0 })
        .from("#pcard32", { x: innerWidth, opacity: 0 })
        .from("#pcard33", { x: innerWidth, opacity: 0 });
      // .from("#pcard34", { opacity: 0 });
    },
  },
};
</script>

<style lang="scss" scoped>
.teamView {
  //background-color: #141414;
}

.contentTeam {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  width: 100%;
  max-width: 1600px;
  //border: 1px solid red;
}
</style>

<template>
  <div id="footer">
    <div class="centerF">
      <div class="this" style="margin: auto 0">
        <div @click="goTo('HomeView')" class="title2" style="cursor: pointer; margin: auto 0; font-size: 24px; line-height: 24px">Hybrid <span style="color: #d9ad59">Factions</span></div>
        <div>
          <i class="fab fa-discord" @click="goToExternal($store.state.social.discord)" v-if="$store.state.social.discord != ''"></i>
          <i class="fab fa-twitter" @click="goToExternal($store.state.social.twitter)" v-if="$store.state.social.twitter != ''"></i>
          <i class="fab fa-instagram" @click="goToExternal($store.state.social.instagram)" v-if="$store.state.social.instagram != ''"></i>
        </div>
      </div>

      <div class="hideMe" style="margin: auto 0">
        <a class="text">All rights reserved.</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Topbar",
  data() {
    return {};
  },
  methods: {
    goToExternal(url) {
      window.open(url);
    },
    goTo(name) {
      console.log(name);
      if (this.$route.name !== name) {
        this.$router.push({ name });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fab {
  color: rgba(217, 173, 89, 1);
  font-size: 25px;
  cursor: pointer;
  opacity: 0.9;
  transition: all 100ms ease-in-out;
  margin-right: 15px;
  &:hover {
    filter: drop-shadow(0px 0px 1px #ffffff);
    opacity: 1;
    transform: translateY(-1px);
  }
}

#footer {
  opacity: 1;
  background: #0e091f;
  padding-bottom: 10px;
  z-index: 5;
}

.centerF {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100px;
  width: 90%;
  max-width: 1400px;
  margin: auto;
}
.text {
  color: white;
  font-size: 15px;
  cursor: pointer;
}
.icon {
  color: #d9ad59;
}
.icon-link {
  color: #d9ad59;
  cursor: pointer;
}

@media screen and (max-width: $layout-breakpoint-medium) {
  .hideMe {
    display: none;
  }
  .this {
    margin: auto;
    width: 100%;
  }
}
</style>

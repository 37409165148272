<template>
  <div class="viewContainer roadmapView" v-bind:style="{ background: $store.state.roadmapView.bgColor }" id="roadmap">
    <div class="center roadmapCenter">
      <p class="title1 rt1">Roadmap</p>
      <div class="roadmap">
        <div class="rLeft hidemob">
          <div class="box hide"></div>
          <div class="box" id="r2">
            <p class="title2 rTitle">{{ $store.state.roadmapView.sections[1].title }}</p>
            <p class="text rText rl" v-html="$store.state.roadmapView.sections[1].text"></p>
          </div>
          <div class="box hide"></div>
          <div class="box" id="r4">
            <p class="title2 rTitle">{{ $store.state.roadmapView.sections[3].title }}</p>
            <p class="text rText rl" v-html="$store.state.roadmapView.sections[3].text"></p>
          </div>
        </div>
        <div class="middle hide" id="rmiddle">
          <div class="circlel cc1" style="background-color: #0f4cf0; filter: drop-shadow(0px 0px 1px #0f4cf0)"></div>
          <div class="circlel cc2" style="background-color: #493ab6; filter: drop-shadow(0px 0px 1px #493ab6)"></div>
          <div class="circlel cc3" style="background-color: #93236c; filter: drop-shadow(0px 0px 1px #93236c)"></div>
          <div class="circlel cc4" style="background-color: #c3123b; filter: drop-shadow(0px 0px 1px #c3123b)"></div>
        </div>
        <div class="rRight hidemob">
          <div class="box" id="r1">
            <p class="title2 rTitle">{{ $store.state.roadmapView.sections[0].title }}</p>
            <p class="text rText rr" v-html="$store.state.roadmapView.sections[0].text"></p>
          </div>
          <div class="box hide"></div>
          <div class="box" id="r3">
            <p class="title2 rTitle">{{ $store.state.roadmapView.sections[2].title }}</p>
            <p class="text rText rr" v-html="$store.state.roadmapView.sections[2].text"></p>
          </div>
          <div class="box hide"></div>
        </div>

        <div class="showmob">
          <div class="box" v-for="(section, i) in $store.state.roadmapView.sections" :key="i" :id="'r' + i">
            <p class="title2 rTitle">{{ $store.state.roadmapView.sections[i].title }}</p>
            <p class="text" v-html="$store.state.roadmapView.sections[i].text"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "Roadmap",
  mounted() {
    if (!this.isMobile()) {
      this.scrollAnimation();
    }
  },
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    },
    scrollAnimation() {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: ".rt1",
            start: "center center",
            end: "center center",
            markers: this.scrollMarker,
            scrub: false,
            pin: false,
          },
        })
        .from(".rt1", { opacity: 0 })
        .from("#r1", { x: innerWidth, opacity: 0 })
        .from("#r2", { x: -innerWidth, opacity: 0 })
        .from("#r3", { x: innerWidth, opacity: 0 })
        .from("#r4", { x: -innerWidth, opacity: 0 });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/app.scss";
.roadmapView {
  background-image: url("~@/assets/imgs/bg-faq.png");
}

.roadmapCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

// .title1 {
//   font-size: 85px;
// }

.roadmap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  margin-top: 50px;
  //border: 1px solid red;
}

.middle {
  margin-top: 0px;
  height: 1520px;
  width: 0;
  margin: 0 35px;
  border-left: 6px solid $white;
  border-image: linear-gradient(to bottom, rgb(0, 81, 255), rgb(255, 0, 0)) 1 100%;
  //filter: drop-shadow(0px 0px 1px #ffffff);
  transition: all 500ms ease-in-out;
  //animation: moveTrailer2 4000ms infinite linear;
}

.circlel {
  width: 35px;
  height: 35px;
  // border: 3px solid $gris-light;
  border-radius: 50%;
  background-color: $white;
  margin-left: -21px;
  margin-top: -6px;
  margin-bottom: 366px;
  // filter: drop-shadow(0px 0px 1px #ffffff);
}

.box {
  width: 100%;
  height: 400px;
  //border: 1px solid red;
}

.rLeft {
  width: 50%;
  text-align: right;
  //border: 1px solid red;
}

.rRight {
  width: 50%;
  text-align: left;
  //border: 1px solid red;
}

.rTitle {
  margin-top: -10px;
  margin-bottom: 5px;
}
.rText {
  //font-size: 16px;
  //max-width: 400px;
}

.rl {
  text-align: right;
}
.rr {
  text-align: left;
}

@media screen and (min-width: $layout-breakpoint-medium) {
  .showmob {
    display: none;
  }
}

@media screen and (max-width: $layout-breakpoint-large) {
  .middle {
    height: 1520px;
  }
}

@media screen and (max-width: $layout-breakpoint-medium) {
  .rl {
    text-align: center;
  }
  .rr {
    text-align: center;
  }
  .roadmap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
  .hide {
    display: none;
  }
  .rLeft {
    width: 100%;
    text-align: center;
  }
  .rRight {
    width: 100%;
    text-align: center;
  }
  .rTitle {
    font-size: 20px;
  }
  .box {
    margin-bottom: 50px;
    height: auto;
  }

  .hidemob {
    display: none;
  }
}
</style>

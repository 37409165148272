<template>
  <div class="viewContainer faqView" id="faq">
    <div class="center centerFAQ">
      <p class="title1">Frequently<br />Asked Questions</p>

      <div class="contFAQ">
        <div class="contFAQ2">
          <div class="question" v-for="(faq, i) in faq1" :key="'1' + i">
            <div class="inline">
              <p class="text" style="text-transform: uppercase; font-size: 25px">
                <b>{{ faq.q }}</b>
              </p>
              <div
                class="clickme"
                @click="
                  faq1Show[i] = !faq1Show[i];
                  $forceUpdate();
                "
              >
                <div v-if="!faq1Show[i]">-</div>
                <div v-if="faq1Show[i]">+</div>
              </div>
            </div>
            <p class="text" style="font-size: 17px" v-if="!faq1Show[i]">{{ faq.r }}</p>
          </div>
        </div>
        <div class="contFAQ2">
          <div class="question" v-for="(faq, i) in faq2" :key="'2' + i">
            <div class="inline">
              <p class="text" style="text-transform: uppercase; font-size: 25px">
                <b>{{ faq.q }}</b>
              </p>
              <div
                class="clickme"
                @click="
                  faq2Show[i] = !faq2Show[i];
                  $forceUpdate();
                "
              >
                <div v-if="!faq2Show[i]">-</div>
                <div v-if="faq2Show[i]">+</div>
              </div>
            </div>
            <p class="text" style="font-size: 17px" v-if="!faq2Show[i]">{{ faq.r }}</p>
          </div>
        </div>
      </div>

      <button class="glow-button" @click="goToExternal($store.state.social.discord)"><i class="fab fa-discord"></i> Join discord</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "FAQ",
  data() {
    return {
      faq1: [this.$store.state.faq[0], this.$store.state.faq[1], this.$store.state.faq[2], this.$store.state.faq[3]],
      faq2: [this.$store.state.faq[4], this.$store.state.faq[5], this.$store.state.faq[6], this.$store.state.faq[7]],
      faq1Show: [false, true, true, true],
      faq2Show: [true, true, true, true],
    };
  },
  methods: {
    goToExternal(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.faqView {
  // background-image: url("~@/assets/imgs/bg-faq.png");
  // background-repeat: no-repeat;
  // background-size: cover;
}

.contFAQ {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.contFAQ2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 48%;
}

.question {
  background: $black;
  border: 1px solid $white;
  padding: 10px 30px;
  border-radius: 40px;
  margin-bottom: 25px;
  transition: all 0.3s ease;
  * {
    transition: all 0.3s ease;
  }
  filter: drop-shadow(0px 0px 2px #ffffff);
}

.inline {
  width: 100%;
  justify-content: space-between;
}

.clickme {
  background: $white;
  border: 1px solid $white;
  color: gold;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 45px;
  margin-top: 6px;
  filter: drop-shadow(0px 0px 1px #ffffff);
  cursor: pointer;
  &:hover {
    background: $white;
    color: $black;
  }
}

.glow-button {
  width: 450px;
  // font-size: 40px;
  // padding: 20px 30px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

@media screen and (max-width: $layout-breakpoint-xlarge) {
  .contFAQ {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .contFAQ2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
}

@media screen and (max-width: $layout-breakpoint-medium) {
  .glow-button {
    width: 100%;
  }
}
</style>
